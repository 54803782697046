import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  statusList: [],
  status: "idle",
  error: null,
};

export const getAllStatus = createAsyncThunk(
  "status/getAllStatus",
  async () => {
    let data;
    try {
      const response = await axios.get(`/status`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addStatus = createAsyncThunk(
  "status/addStatus",
  async ({ code, label }) => {
    let data;
    try {
      const response = await axios.post(`/status`, { code, label });
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteStatus = createAsyncThunk(
  "status/deleteStatus",
  async (id) => {
    let data;
    try {
      const response = await axios.patch(`/status/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "status",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllStatus.pending]: (state) => {
      state.status = "loading";
    },
    [getAllStatus.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.statusList = action.payload;
    },
    [getAllStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [addStatus.pending]: (state) => {
      state.status = "loading";
    },
    [addStatus.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [addStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [deleteStatus.pending]: (state) => {
      state.status = "loading";
    },
    [deleteStatus.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [deleteStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
